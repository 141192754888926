import { Outlet, Link } from "react-router-dom";
import { useState } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';

import Header from './../Header';
import BlockPageMenu from './../Block/BlockPageMenu';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#1b1b1b',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#ffa31a',
      dark: '#fff',
      contrastText: '#000',
    },
  },
});

const Page = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Drawer open={isMenuOpen}>
        <BlockPageMenu toggleSidebar={ isMenuOpen => setIsMenuOpen(isMenuOpen) } />
      </Drawer>  

      <Header toggleSidebar={ isMenuOpen => setIsMenuOpen(isMenuOpen) } />

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Outlet />
      </Container>
    </ThemeProvider>
  )
};

export default Page;