import { useState } from 'react';
import { firestore } from './../Backend/firebase';
import {Timestamp} from 'firebase/firestore';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';  
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import BlockListTransactions from './../Block/BlockListTransactions';
import { useCollectionData } from 'react-firebase-hooks/firestore';

  const converter = {
      toFirestore(post) {
          return {
              date: post.date,
              name: post.name,
              gastusin_id: post.gastusin_id,
              cost: post.cost
          }
      },
      fromFirestore(snapshot, options) {
          const data = snapshot.data(options);
          return {
              id: snapshot.id,
              date: data.date,
              name: data.name,
              cost: data.cost
          }
      },
  } 

  const gastusinRef = firestore.collection('gastusin').withConverter(converter);
  const transactionsRef = firestore.collection('transactions').withConverter(converter);

  const query = transactionsRef.orderBy('date');

const Transactions = () => {

  const [mgaGastusin] = useCollectionData(gastusinRef);
  const [mgaTransactions, loading, error] = useCollectionData(query);

  return (<>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AddForm data={mgaGastusin}/>
        </Grid>
        <Grid item xs={6}>
          <AddAllotmentForm />
        </Grid>
      </Grid>
    </Box>    
    
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >    
    {loading && <CircularProgress color="inherit" /> }
    </Backdrop>
    <BlockListTransactions data={mgaTransactions} />
  </>);
};

const AddForm = (props) => {

  const mgaGastusin = props.data;
  const [name, setName] = useState('');
  const [date, setDate] = useState(null);
  const [cost, setCost] = useState('');
  const [formIsValid, setformIsValid] = useState('');

  const handleChange = (event) => {
    setName(event.target.value);
  };  

  const addGastos = async (e) => {
    e.preventDefault();
    console.log(name);
    console.log(`date: ${date} name: ${name} cost: ${cost}`);

    await transactionsRef.add({
      date: Timestamp.fromDate(new Date(date)),
      name: name.name,
      gastusin_id: name.id,
      cost: -Math.abs(cost),
    });

  }

  return (<>
    <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <Box component="form" onSubmit={addGastos} sx={{ mt: 1 }}>
      <DatePicker
          id="field-date" 
          label="Date"
          size="small"
          variant="outlined" 
          value={date} 
          onChange={(newValue) => {
            setDate(newValue);
          }}
          renderInput={(params) => <TextField fullWidth {...params} sx={{ mb: 2 }} />}
      />          
        <Autocomplete
          disablePortal
          id="field-gastos"
          options={ mgaGastusin ? mgaGastusin : null }
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setName(newValue);
            console.log(newValue);
          }}          
          size="small"
          renderInput={(params) => <TextField fullWidth {...params} label="Gastos" sx={{ mb: 2 }} />}
        />        
        <TextField 
          sx={{ mb: 2 }}
          fullWidth
          id="field-cost" 
          label="Costs"
          size="small"
          variant="outlined" 
          value={cost} 
          onChange={(e) => setCost(e.target.value)}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">₱</InputAdornment>,
          }}
           />                    
        <Button
          sx={{ mb: 2 }}
          fullWidth 
          type="submit" 
          disabled={formIsValid} 
          variant="contained"
          color="secondary">
          Add
        </Button>
      </Box>
    </Box>
  </>);
}

const AddAllotmentForm = () => {

  const [date, setDate] = useState(null);
  const [cost, setCost] = useState('');
  const [formIsValid, setformIsValid] = useState('');

  const addGastos = async (e) => {
    e.preventDefault();
    
    await transactionsRef.add({
      date: Timestamp.fromDate(new Date(date)),
      name: 'Allotment',
      gastusin_id: 'allotment-class-S',
      cost: Math.abs(cost),
    });

  }

  return (<>
    <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <Box component="form" onSubmit={addGastos} sx={{ mt: 1 }}>
      <DatePicker
          id="field-date2" 
          label="Date"
          size="small"
          variant="outlined" 
          value={date} 
          onChange={(newValue) => {
            setDate(newValue);
          }}
          renderInput={(params) => <TextField fullWidth {...params} sx={{ mb: 2 }} />}
      />
        <TextField 
          sx={{ mb: 2 }}
          fullWidth
          id="field-cost2" 
          label="Costs"
          size="small"
          variant="outlined" 
          value={cost} 
          onChange={(e) => setCost(e.target.value)}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">₱</InputAdornment>,
          }}
           />                    
        <Button
          sx={{ mb: 2 }}
          fullWidth 
          type="submit" 
          disabled={formIsValid} 
          variant="contained"
          color="secondary">
          Add
        </Button>
      </Box>
    </Box>
  </>);
}

export default Transactions;