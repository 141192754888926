import * as React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function BlockPageMenu(props) {
  const mainMenu = [
    { 
      text: 'Dashboard',
      link: '/',
      icon: <HomeIcon />
    },
    { 
      text: 'Transactions',
      link: '/Transactions',
      icon: <AssignmentIcon />
    },
    { 
      text: 'Settings',
      link: '/settings',
      icon: <Settings />
    }        
  ];

  return (
    <Box 
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={ ()=> props.toggleSidebar(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />      
      <nav aria-label="main mailbox folders">
        <List onClick={ ()=> props.toggleSidebar(false)}>
          {
            mainMenu.map((data, index) => 
            <ListItem disablePadding key={index}>
              <ListItemButton component={Link} to={data.link}>
                <ListItemIcon>
                  {data.icon}
                </ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItemButton>    
            </ListItem>
            )
          }
        </List>
      </nav>
    </Box>
  );
}

export default BlockPageMenu;