import { useState } from 'react';
import { firestore } from './../Backend/firebase';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import BlockListItems from './../Block/BlockListItems';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const converter = {
      toFirestore(post) {
          return {
              name: post.name,
          }
      },
      fromFirestore(snapshot, options) {
          const data = snapshot.data(options);
          return {
              id: snapshot.id,
              name: data.name,
          }
      },
} 

const gastusinRef = firestore.collection('gastusin').withConverter(converter);
const query = gastusinRef.orderBy('name').limit(69);

const Settings = () => {

  const [mgaGastos, loading, error] = useCollectionData(query);

  return (<>
    <AddForm />
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >    
    {loading && <CircularProgress color="inherit" /> }
    </Backdrop>
    <BlockListItems data={mgaGastos} />
  </>);
};

const AddForm = () => {

  const [formValue, setFormValue] = useState('');

  const addGastusin = async (e) => {
    e.preventDefault();

    await gastusinRef.add({
      name: formValue
    });

    setFormValue('');
  }

  return (<>
    <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <Box component="form" onSubmit={addGastusin} sx={{ mt: 1 }}>
        <TextField 
          fullWidth
          id="field-gastos-name" 
          label="Gastusin" 
          variant="outlined" 
          value={formValue} 
          onChange={(e) => setFormValue(e.target.value)} />
        <Button
          sx={{ mt: 3, mb: 2 }}
          fullWidth 
          type="submit" 
          disabled={!formValue} 
          variant="contained"
          color="secondary">
          Add
        </Button>
      </Box>
    </Box>
  </>);
}

export default Settings;