import { useState } from 'react';

import { db } from './../Backend/firebase';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { doc, deleteDoc } from "firebase/firestore";

function BlockListTransactions(props) {
  const mgaGastos = props.data;
  let total = 0;

  return (<>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Gastos</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mgaGastos && mgaGastos.map(item => {
            console.log(total = total + item.cost);
            return(<BlockItem data={item} />); 
         })}
        </TableBody>  
      </Table>
    </TableContainer>
  </>);
}


function BlockItem(props) {
  const { id, date, name, cost } = props.data;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    deleteDoc(doc(db,'transactions',id));
    handleClose();
  }  

  return (
    <TableRow key={id} className={name}>
      <TableCell >{ date.toDate().toString() }</TableCell>
      <TableCell >{name}</TableCell>
      <TableCell >{cost} php</TableCell>
      <TableCell >
        <IconButton
          size="small"
          aria-label="display more actions"
          onClick={handleClick}
        >
          <MoreIcon />
        </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Edit</MenuItem>
        <MenuItem onClick={() => handleDelete(id)} >Delete</MenuItem>
      </Menu>          
      </TableCell>
    </TableRow>
  );
}

export default BlockListTransactions;